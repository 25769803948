body {
	margin: 0;
	background-color: #171e24;
}

/* width */
::-webkit-scrollbar {
	opacity: 0.5;
	width: 0.5em;
}

/* Track */
::-webkit-scrollbar-track {
	background: #171e24;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: red;
	border-width: 0.5px;
	border: black;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
